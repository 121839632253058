import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Paper, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [userLocation, setUserLocation] = useState(null);
    const navigate = useNavigate();

    // Latitude e Longitude específicas
    const allowedLatitude = -23.5651605;
    const allowedLongitude = -46.5422197;
    const allowedRadius =1; // Raio de aceitação (10 metros, por exemplo)

    useEffect(() => {
        // Pegar a localização do usuário ao carregar o componente
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    });
                },
                (error) => {
                    setLocationError('Erro ao capturar localização. Verifique as permissões.');
                }
            );
        } else {
            setLocationError('Geolocalização não é suportada pelo navegador.');
        }
    }, []);

    const isWithinAllowedLocation = () => {
        if (!userLocation) return false;
        
        const latDiff = Math.abs(userLocation.latitude - allowedLatitude);
        const lonDiff = Math.abs(userLocation.longitude - allowedLongitude);

        return latDiff <= allowedRadius && lonDiff <= allowedRadius;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isWithinAllowedLocation()) {
            setError('Login permitido apenas em localizações específicas.');
            return;
        }

        try {
            const response = await fetch('https://apil.wh.tec.br/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.user.token);
                localStorage.setItem('username', data.user.user);
                navigate('/pagina-inicial');
            } else {
                setError('Login falhou. Verifique suas credenciais.');
            }
        } catch (error) {
            setError('Ocorreu um erro ao tentar fazer login. Tente novamente.');
        }
    };

    return (
        <Grid
            container
            component="main"
            sx={{
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(135deg, #3f51b5 30%, #1a237e 90%)',
            }}
        >
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={10} sx={{ borderRadius: '16px', padding: 4, backgroundColor: '#fff' }}>
                <Typography component="h1" variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold', mb: 3, color: '#3f51b5' }}>
                    Faça login
                </Typography>

                {locationError && <Typography color="error" variant="body2" sx={{ textAlign: 'center', mb: 2 }}>{locationError}</Typography>}
                {error && <Typography color="error" variant="body2" sx={{ textAlign: 'center', mb: 2 }}>{error}</Typography>}

                <Box component="form" noValidate onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Usuario"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        InputProps={{
                            sx: {
                                borderRadius: '12px',
                                backgroundColor: '#f5f5f5',
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#ddd' },
                            },
                        }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Senha"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            sx: {
                                borderRadius: '12px',
                                backgroundColor: '#f5f5f5',
                                '& .MuiOutlinedInput-notchedOutline': { borderColor: '#ddd' },
                            },
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            backgroundColor: '#3f51b5',
                            padding: '10px 0',
                            borderRadius: '30px',
                            textTransform: 'none',
                            fontWeight: 'bold',
                            '&:hover': { backgroundColor: '#303f9f' },
                        }}
                    >
                        Acessar
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Login;
