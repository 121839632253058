import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Typography, Button, IconButton, MenuItem, Select, FormControl, InputLabel, Link } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InfoIcon from '@mui/icons-material/Info';

function LeadCard({ lead, onViewDetails, onStatusChange, disabled }) {
    const [status, setStatus] = useState('');
    const [showAdditionalPhones, setShowAdditionalPhones] = useState(false);

    useEffect(() => {
        setStatus(lead.status || '');
    }, [lead.status]);

    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        setStatus(newStatus);

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://apil2.wh.tec.br/base_sp/status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({ leadId: lead.id, status: newStatus }),
            });

            if (response.ok) {
                onStatusChange(lead.id, newStatus);
            } else {
                console.error('Falha ao atualizar status:', response.statusText);
            }
        } catch (error) {
            console.error('Erro ao tentar atualizar status:', error);
        }
    };

    const truncateName = (name) => {
        return name.length > 32 ? name.substring(0, 30) + '...' : name;
    };

    const capitalizeName = (name) => {
        return name
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const toggleAdditionalPhones = () => {
        setShowAdditionalPhones(!showAdditionalPhones);
    };

    const renderPhone = (label, phone, isWhatsApp) => {
        if (!phone) return null;
        return (
            <Typography variant="body1" key={label}>
                {label}: {phone}
                {isWhatsApp && (
                    <IconButton size="small">
                        <WhatsAppIcon color="success" />
                    </IconButton>
                )}
            </Typography>
        );
    };

    return (
        <Card
            sx={{
                borderRadius: '16px',
                backgroundColor: disabled ? '#1a1a1a' : '#242424',
                color: '#fff',
                padding: '16px',
                marginBottom: '16px',
                opacity: disabled ? 0.7 : 1,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.3s ease',
                '&:hover': {
                    transform: 'scale(1.02)',
                },
            }}
        >
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ textTransform: 'capitalize', fontWeight: '600', color: '#e0e0e0' }}>
                            {truncateName(capitalizeName(lead.nome_cliente))}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#999" }}>{lead.processo}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ fontWeight: '600', color: '#fff' }}>
                            Celular: {disabled ? 'xxxx-xxxx' : lead.telefone_principal}
                            {!disabled && lead.whatsapp && (
                                <IconButton size="small">
                                    <WhatsAppIcon color="success" />
                                </IconButton>
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link onClick={toggleAdditionalPhones} sx={{ cursor: 'pointer', color: '#ff8c00', fontWeight: 'bold', textDecoration: 'none' }}>
                            {showAdditionalPhones ? 'Ocultar Telefones' : 'Ver Mais Telefones'}
                        </Link>
                    </Grid>

                    {showAdditionalPhones && (
                        <>
                            {renderPhone("Telefone Adicional 1", lead.telefone_adicional1, lead.telefone_adicional1_whatsapp)}
                            {renderPhone("Telefone Adicional 2", lead.telefone_adicional2, lead.telefone_adicional2_whatsapp)}
                            {renderPhone("Telefone Adicional 3", lead.telefone_adicional3, lead.telefone_adicional3_whatsapp)}
                            {renderPhone("Telefone Adicional 4", lead.telefone_adicional4, lead.telefone_adicional4_whatsapp)}
                            {renderPhone("Telefone Adicional 5", lead.telefone_adicional5, lead.telefone_adicional5_whatsapp)}
                        </>
                    )}

                    <Grid item xs={9}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>Status da Lead</InputLabel>
                            <Select
                                value={status}
                                onChange={handleStatusChange}
                                label=""
                                displayEmpty
                                disabled={disabled}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(255, 255, 255, 0.5)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        color: '#fff',
                                    },
                                }}
                            >
                                <MenuItem value=""><em>Selecione</em></MenuItem>
                                <MenuItem value="enviado">Enviado</MenuItem>
                                <MenuItem value="negociando">Negociando</MenuItem>
                                <MenuItem value="nao responde">Não Responde</MenuItem>
                                <MenuItem value="venda realizada">Venda Realizada</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#3043ec',
                                color: '#fff',
                                borderRadius: '50%',
                                width: '50px',
                                height: '50px',
                                minWidth: '50px',
                                minHeight: '50px',
                                '&:hover': {
                                    backgroundColor: '#ff8c00',
                                },
                            }}
                            onClick={() => onViewDetails(lead)}
                            disabled={disabled}
                        >
                            <InfoIcon />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default LeadCard;
