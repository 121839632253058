import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Modal, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Pagination } from '@mui/material';
import LeadCard from './LeadCard'; // Componente para os cards das leads
import TopMenu from './TopMenu'; // Menu simplificado com saudação e logout

function PaginaInicial() {
    const [leads, setLeads] = useState([]);
    const [selectedLead, setSelectedLead] = useState(null);
    const [open, setOpen] = useState(false); // Modal de lead
    const [notificationOpen, setNotificationOpen] = useState(false); // Modal de notificação
    const [visibleLeads, setVisibleLeads] = useState(0);
    const [shouldHideNextDayLeads, setShouldHideNextDayLeads] = useState(false); // Ocultar leads do próximo dia
    const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
    const leadsPerPage = 9; // Número de cards por página

    // Função para buscar as leads de uma data específica
    const fetchLeads = async (date) => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        const formattedDate = date ? date.toISOString().split('T')[0] : null;

        let url = 'https://apil2.wh.tec.br/base_sp/';
        if (formattedDate) {
            url += `?date=${formattedDate}`;
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'consultor-name': username
                }
            });

            if (response.ok) {
                const data = await response.json();

                if (data.length === 0) {
                    console.log('Nenhuma lead encontrada para a data de hoje. Buscando leads antigas...');
                    fetchOldLeads(); // Chama a função para buscar leads antigas
                } else {
                    setLeads(data);
                    const countVisibleLeads = data.reduce((count, lead) => {
                        return count + (lead.status && lead.status !== '' ? 1 : 0);
                    }, 0);
                    setVisibleLeads(countVisibleLeads);
                }
            } else {
                console.error('Falha ao buscar leads.');
            }
        } catch (error) {
            console.error('Erro ao tentar buscar leads:', error);
        }
    };

    // Função para buscar leads antigas (caso não haja leads na data de hoje)
    const fetchOldLeads = async () => {
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');
        
        const url = 'https://apil2.wh.tec.br/base_sp/';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                    'consultor-name': username
                }
            });

            if (response.ok) {
                const data = await response.json();
                setLeads(data);
                const countVisibleLeads = data.reduce((count, lead) => {
                    return count + (lead.status && lead.status !== '' ? 1 : 0);
                }, 0);
                setVisibleLeads(countVisibleLeads);
            } else {
                console.error('Falha ao buscar leads antigas.');
            }
        } catch (error) {
            console.error('Erro ao tentar buscar leads antigas:', error);
        }
    };

    // Busca as leads da data de hoje ao carregar a página
    useEffect(() => {
        const today = new Date(); // Pega a data de hoje
        fetchLeads(today); // Chama a função para buscar as leads da data de hoje
    }, []);

    const handleViewDetails = (lead) => {
        setSelectedLead(lead);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleStatusChange = (leadId, newStatus) => {
        setLeads(prevLeads => prevLeads.map(lead =>
            lead.id === leadId ? { ...lead, status: newStatus } : lead
        ));

        if (newStatus === 'enviado') {
            setVisibleLeads(prev => prev + 1);
        }
    };

    const handleNotificationClose = () => {
        setNotificationOpen(false);
    };

    // Função para lidar com a mudança de página
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    // Cálculos para a paginação
    const indexOfLastLead = currentPage * leadsPerPage;
    const indexOfFirstLead = indexOfLastLead - leadsPerPage;
    const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);
    const totalPages = Math.ceil(leads.length / leadsPerPage); // Número total de páginas

    return (
        <Box
        sx={{
            minHeight: '100vh',
            position: 'relative',
            overflow: 'hidden',
            background: 'linear-gradient(135deg, #1e1e30, #3b3b51, #60607a)', // Cores mais vibrantes e suaves
            backgroundSize: '200% 200%',
            animation: 'gradientShift 20s ease infinite', // Animação mais longa para suavidade
            '@keyframes gradientShift': {
                '0%': { backgroundPosition: '0% 0%' },
                '50%': { backgroundPosition: '100% 100%' },
                '100%': { backgroundPosition: '0% 0%' }
            },
            boxShadow: 'inset 0 0 80px rgba(0, 0, 0, 0.5)', // Sombra interna suave para profundidade
            backdropFilter: 'blur(4px)', // Efeito de desfoque sutil
        }}
        
        >
            <TopMenu onDateChange={(newDate) => fetchLeads(newDate)} />

            <Box sx={{ padding: '20px' }}>
                <Grid container spacing={3}>
                    {currentLeads.map((lead, index) => (
                        <Grid item xs={12} sm={6} md={4} key={lead.id}>
                            <LeadCard
                                lead={lead}
                                onViewDetails={handleViewDetails}
                                onStatusChange={handleStatusChange}
                                disabled={index > visibleLeads || shouldHideNextDayLeads}
                            />
                        </Grid>
                    ))}
                </Grid>

                {/* Paginação */}
                {leads.length > leadsPerPage && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            sx={{
                                '& .MuiPaginationItem-root': {
                                    color: '#fff', // Cor para todas as páginas
                                },
                                '& .MuiPaginationItem-root.Mui-selected': {
                                    backgroundColor: '#1976d2', // Cor de fundo do item selecionado
                                    color: '#fff', // Cor do texto do item selecionado
                                },
                            }}
                        />
                    </Box>
                )}

                <Dialog
                    open={notificationOpen}
                    onClose={handleNotificationClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Atualize o Status dos Leads"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Por favor, atualize o status das leads para "não respondido", "não tem interesse" ou "negociando" antes do fim do expediente.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNotificationClose} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Detalhes do Cliente
                        </Typography>
                        {selectedLead && (
                            <div>
                                <Typography variant="body1"><strong>Processo:</strong> {selectedLead.processo}</Typography>
                                <Typography variant="body1"><strong>Nome:</strong> {selectedLead.nome_cliente}</Typography>
                                <Typography variant="body1"><strong>CPF:</strong> {selectedLead.cpf_cliente}</Typography>
                                <Typography variant="body1"><strong>Email:</strong> {selectedLead.e_mail}</Typography>
                                <Typography variant="body1"><strong>Valor de Entrada:</strong> {selectedLead.valor_entrada}</Typography>
                                <Typography variant="body1"><strong>Marca/Modelo:</strong> {selectedLead.marca_modelo}</Typography>
                                <Typography variant="body1"><strong>Ano do Veículo:</strong> {selectedLead.ano_veiculo}</Typography>
                                <Typography variant="body1"><strong>Cor do Veículo:</strong> {selectedLead.cor_veiculo}</Typography>
                                <Typography variant="body1"><strong>Chassi:</strong> {selectedLead.chassi_veiculo}</Typography>
                                <Typography variant="body1"><strong>Renavam:</strong> {selectedLead.renavam_veiculo}</Typography>
                                <Typography variant="body1"><strong>Placa:</strong> {selectedLead.placa_veiculo}</Typography>
                            </div>
                        )}
                        <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
                            Fechar
                        </Button>
                    </Box>
                </Modal>
            </Box>
            {/* Footer */}
    <Box sx={{ padding: '10px', textAlign: 'center', color: '#fff', mt: 'auto' }}>
        <Typography variant="body2">
            Created by W&H - Group
        </Typography>
    </Box>

        </Box>
    );
}

export default PaginaInicial;
