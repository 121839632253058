import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Login';
import PaginaInicial from './PaginaInicial';
import PrivateRoute from './ProtectedRoute'; // Importa a rota protegida

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route 
                    path="/pagina-inicial" 
                    element={
                        <PrivateRoute>
                            <PaginaInicial />
                        </PrivateRoute>
                    } 
                />
                {/* Outras rotas */}
            </Routes>
        </Router>
    );
}

export default App;
